<template>
    <div class="login">
        <div class="login-box">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item class="login-title">
                    <h2>哪儿疼大数据中心</h2>
                </el-form-item>
                <el-form-item prop="username">
                    <el-input prefix-icon="el-icon-user" v-model="form.username"  placeholder="请输入用户名"></el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input prefix-icon="el-icon-lock" v-model="form.password" type="password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item style="padding-top: 22px;">
                    <el-button type="primary" @click="login">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="copyright">
			哪儿疼版权所有
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Login',
        data() {
            return {
               form:{
                   username:null,
                   password:null
               },
                rules: {
                    username: [
                        {required: true, message: '请输入用户名', trigger: 'blur'},
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                    ],
                }
            }
        },
        methods: {
            login () {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('login',this.form).then((res)=>{
                            this.$message({
                                type: 'success',
                                message: res.msg
                            });
                        }).catch((err)=>{
                            console.error(err)
                        })
                    } else {
                        this.$message.error('请输入正确的用户名和密码');
                    }
                });
            },
        }
    }
</script>
<style lang="scss">
    $corner:rgb(46, 96, 153);
    $border:rgb(124, 231, 253);
    *{
        margin: 0;
        padding: 0;
    }
    .el-form-item__content{
        button{
            color: $border;
            background-color: rgba(255,255,255,0);
        }
    }
    .login{
        color: white;
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url(../static/image/bce37a4946becc8b2af52d969cb8993f.png);
        .login-box{
            text-align: center;
            font-weight: 400;
            font-size: 26px;
            margin: 0 auto;
            padding: 15px 40px;
            //box-shadow: 0 0 25px;
            border: 2px solid $border;
            border-radius: 8px;
            position: relative;
            z-index: 0;
            &:before {
                position: absolute;
                top: -2px;
                bottom: -2px;
                left: 30px;
                width: calc(100% - 60px);
                content: "";
                border-top: 2px solid $corner;
                border-bottom: 2px solid $corner;
                z-index: -1;
            }
            &:after {
                position: absolute;
                top: 30px;
                right: -2px;
                left: -2px;
                height: calc(100% - 60px);
                content: "";
                border-right: 2px solid $corner;
                border-left: 2px solid $corner;
                z-index: -1;
            }
            .login-title{
                margin-bottom: 50px;
            }
            .el-form-item__label{
                color: white;
            }
            .el-input__inner{
                font-size: 16px;
                line-height: 30px;
                width: 100%;
                color: white;
                outline: none;
                border: none;
                padding-left: 40px;
                background-color: transparent;
                border-bottom: 1px solid #d3d7f7;
                border-radius: 0;
            }
        }
    }
    .el-button{
        width: 100%;
    }
    .copyright{
        width: 100%;
        position: fixed;
        bottom: 50px;
        text-align: center;
        color: white;
        line-height: 22px;
    }
</style>


